export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [];

export const dictionary = {
		"/(admin)": [6,[2]],
		"/(admin)/contents": [7,[2]],
		"/(admin)/dashboard": [8,[2]],
		"/lobby": [15,[4]],
		"/login": [16],
		"/(admin)/models": [9,[2]],
		"/(admin)/organizations": [10,[2]],
		"/profile": [17],
		"/solotest": [18,[5]],
		"/(admin)/test": [11,[2,3]],
		"/(admin)/users": [12,[2]],
		"/(admin)/utility": [13,[2]],
		"/(admin)/waytags": [14,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';